<template>
  <b-card v-if="series" no-body class="mh-100">
    <b-card-header class="border-bottom p-0">
      <div class="w-100 py-1 d-flex align-items-center justify-content-center">
        <h5 class="mb-0">{{ title }}</h5>
      </div>
    </b-card-header>
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-bottom border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> {{ $t("current") }} </b-card-text>
        <h5 class="font-weight-bolder mb-0">
          {{ leftCount }}
        </h5>
      </b-col>

      <b-col
        cols="6"
        class="border-bottom d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> {{ $t("total") }}</b-card-text>
        <h5 class="font-weight-bolder mb-0">
          {{ maxCount || "-" }}
        </h5>
      </b-col>
    </b-row>
    <span class="text-muted font-weight-bold text-center mt-1">
      {{ 1 > leftCount ? $t("youDontHavaAnyRight") : "" }}
    </span>
    <!-- apex chart -->
    <vue-apex-charts
      ref="chart"
      type="radialBar"
      height="250"
      class="mt-1"
      :options="goalOverviewRadialBar"
      :series="[...series]"
    />
    <div v-if="toLink" class="d-flex justify-content-end mt-auto">
      <router-link :to="toLink">
        <b-button class="mx-2 my-1" variant="outline-success">
          <feather-icon icon="ShoppingCartIcon" class="mr-50" size="20" />
          <span class="align-middle">
            {{ $t("buyCredits", { type: title }) }}
          </span>
        </b-button>
      </router-link>
    </div>

    <!-- <div v-if="data.series[0] < 20" class="px-2 h5 text-warning">
      <small v-if="data.series[1] > 20">
        <br />
        <br />
      </small>
      <small v-else>{{ $t("yourPackageIsLow") }}</small>
    </div> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import dayjs from "dayjs";
const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#51e5a8";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BButton,
    VueApexCharts,
  },
  props: {
    leftCount: {
      type: [Number, String],
      required: true,
    },
    maxCount: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    series: {
      type: [Number, String, Array],
      required: false,
      default: "0",
    },
    toLink: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      dayjs,
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.series >= 20 ? "#51d618" : "#fc0303"],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [
              this.series >= 20 ? $themeColors.success : $themeColors.primary,
            ],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.mh-100 {
  min-height: 100%;
}
</style>
